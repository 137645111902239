import { createContext, useContext } from "react";

// Create a Context with a default value of undefined
export const ReTokenContext = createContext(undefined);

// Custom hook to use the RetokenContext
export const useReToken = () => {
    const context = useContext(ReTokenContext);
    if (context === undefined) {
        throw new Error("useReToken must be used within a ReTokenProvider");
    }
    return context;
};

export const Context = createContext(undefined);

export const useGeneralContext = () => {
    /**
     * Intended to be used for obtaining things like current user, authentication credentials and more.
     */
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error(`${useGeneralContext.name} must be used inside contextProvider`);
    }
    return context;
};
