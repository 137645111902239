import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useGeneralContext } from "context/provider/hooks";

export default function Guardian({ children }) {
    const { user } = useGeneralContext();
    if (user) {
        return children;
    }
    return <Navigate to={"/authentication/sign-in"}></Navigate>;
}

Guardian.propTypes = {
    children: PropTypes.node.isRequired,
};

export function InverseGuardian({ children }) {
    /**
     * Intended for using in unprotected views like login and sign up to avoid allowing singing in twice a row
     */
    const { user } = useGeneralContext();
    const location = useLocation();

    if (
        user &&
        ["/authentication/sign-in", "/authentication/sign-up"].includes(location.pathname)
    ) {
        return <Navigate to={"/dashboard"}></Navigate>;
    }
    return children;
}

InverseGuardian.propTypes = {
    children: PropTypes.node.isRequired,
};
