import React, { useEffect, useState } from "react";
import { Context } from "./hooks";
// import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import PropTypes from "prop-types";

// const ReTokenProvider = ({ children }) => {
//   const [retoken, setReToken] = useState(""); // Initialize with an empty string or any default value
//   const [refresh, setRefresh] = useState(false);

//   return (
//     <ReTokenContext.Provider value={{ retoken, refresh: () => setRefresh(true) }}>
//       {children}
//       <GoogleReCaptcha
//         onVerify={(token) => {
//           setReToken(token);
//         }}
//         refreshReCaptcha={refresh}
//       ></GoogleReCaptcha>
//     </ReTokenContext.Provider>
//   );
// };

// ReTokenProvider.propTypes = {
//   children: PropTypes.node.isRequired,
// };

const ContextProvider = ({ children }) => {
    /* Store reusable data about the current user, data like user's name, partial email and anything else may need */
    const [user, setUser] = useState(undefined);
    /* Store here the oauth tokens to login into the system */
    const [auth, setAuth] = useState(undefined);
    return (
        <Context.Provider value={{ user, setUser, auth, setAuth }}> {children} </Context.Provider>
    );
};

ContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { ContextProvider };
